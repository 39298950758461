/* .date-wrapper .react-datepicker-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
} */



.bg-light.rmdp-wrapper,
.bg-light .rmdp-month-picker,
.bg-light .rmdp-year-picker,
.bg-light .rmdp-time-picker div input,
.rmdp-container .bg-light.ep-arrow::after {
  background-color:#fff !important;
  color:  #312f2f !important;
}

.bg-light .rmdp-day:not(.rmdp-deactive),
.bg-light .rmdp-time-picker div .rmdp-am,
.bg-light .rmdp-header-values,
.bg-light .rmdp-panel-header {
  color: #312f2f !important;
}

.bg-light .rmdp-day.rmdp-range {
  color: #312f2f !important;
}

.bg-light .rmdp-panel-body li {
  color: #312f2f !important;
}

.bg-light .rmdp-day.rmdp-deactive,
.bg-light .rmdp-day.rmdp-disabled {
  color: #87898b !important;
}

.rmdp-container .bg-light.ep-arrow[direction="top"] {
  border-bottom: 1px solid #f5f5f5 !important;
}

.rmdp-container .bg-light.ep-arrow[direction="left"] {
  border-right: 1px solid #f5f5f5 !important;
}

.rmdp-container .bg-light.ep-arrow[direction="right"] {
  border-left: 1px solid #f5f5f5 !important;
}

.rmdp-container .bg-light.ep-arrow[direction="bottom"] {
  border-top: 1px solid #f5f5f5 !important;
}


.bg-dark.rmdp-wrapper,
.bg-dark .rmdp-month-picker,
.bg-dark .rmdp-year-picker,
.bg-dark .rmdp-time-picker div input,
.rmdp-container .bg-dark.ep-arrow::after {
  background-color: #0b1727 !important;
  color: #c4c7ca !important;
}

.bg-dark .rmdp-day:not(.rmdp-deactive),
.bg-dark .rmdp-time-picker div .rmdp-am,
.bg-dark .rmdp-header-values,
.bg-dark .rmdp-panel-header {
  color: #c4c7ca !important;
}

.bg-dark .rmdp-day.rmdp-range {
  color: white !important;
}

.bg-dark .rmdp-panel-body li {
  color: #f5f5f5 !important;
}

.bg-dark .rmdp-day.rmdp-deactive,
.bg-dark .rmdp-day.rmdp-disabled {
  color: #87898b !important;
}

.rmdp-container .bg-dark.ep-arrow[direction="top"] {
  border-bottom: 1px solid #312f2f !important;
}

.rmdp-container .bg-dark.ep-arrow[direction="left"] {
  border-right: 1px solid #312f2f !important;
}

.rmdp-container .bg-dark.ep-arrow[direction="right"] {
  border-left: 1px solid #312f2f !important;
}

.rmdp-container .bg-dark.ep-arrow[direction="bottom"] {
  border-top: 1px solid #312f2f !important;
}