.brackets-page {
  .bracket-header {
    .sub-header {
    }

    .sub-items {
      li {
        &:first-child,
        &:last-child {
          width: auto;
        }

        strong {
          + .badge {
            margin-left: 5px;
          }
        }
      }
    }
  }
}

.bracket {
  &:after {
    display: block;
    content: '';
    clear: both;
  }

  .bracket-round-header {
    margin: 0 0 0.75em 1.5em;
  }

  .seed {
    min-width: 200px;

    &:after,
    &:before {
      border-color: var(--falcon-avatar-name-bg) !important;
    }

    .seed-item {
      position: relative;
      background: none !important;
      box-shadow: none !important;

      .round-id {
        font-size: 11px;
        line-height: 15px;
        padding: 1px 5px;
        width: auto !important;
        min-width: 30px !important;
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translateY(-50%);
        border: 1px solid var(--falcon-avatar-name-bg);
        background: #232e3c;
        text-align: center;
        border-radius: 100px;
        color: white;
        z-index: 2;
        font-weight: 700;
      }

      .seed-team {
        padding: 0;
        border-color: rgba(255, 255, 255, 0.13);
        background: var(--falcon-badge-soft-primary-background-color);
        color: var(--falcon-badge-soft-primary-color);
        border-radius: 5px;
        border-left-width: 1px;
        min-height: 40px;

        * {
          color: var(--falcon-badge-soft-primary-color);
        }

        &:first-child {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          margin-bottom: 1px;
        }

        &:nth-child(2) {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

        .participant-status {
          display: flex;
          width: 100%;
        }

        .avatar {
          border-radius: 6px;
          height: 40px;
          width: 40px;
          color: white !important;

          > * {
            border-radius: 6px !important;
            height: 40px;
            width: 40px;
            color: white !important;
          }

          * {
            color: white !important;
          }

          img {
            width: 100%;
            height: 100%;
            border-radius: 6px;
            object-fit: cover;
          }
        }

        .user-info-holder {
          padding: 0 0 0 5px;
          text-align: left;
        }

        .user-info {
          display: flex;
          align-items: center;
          position: relative;
          padding-right: 35px;

          .user-flag {
            width: 16px;
            min-width: 16px;
            margin-right: 5px;

            img {
              max-width: 100% !important;
            }
          }
          .user-name {
            font-size: 13px;
            font-weight: 700;
            line-height: 1.25;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            width: 160px;
          }
        }

        .winner-flag {
          background: var(--falcon-avatar-name-bg);
          color: white !important;
          position: absolute;
          padding: 3px;
          width: 25px;
          right: 5px;
          top: 3px;
          font-size: 11px;
          line-height: 12px;
        }

        .academy-name {
          font-size: 11px;
          line-height: 1.1;
          display: block;
        }

        .medal {
        }
      }
    }
  }
}

.dark {
  .brackets-page {
    .bracket-header * {
      color: white !important;
    }
  }

  .bracket {
    color: white !important;

    .seed {
      &:after,
      &:before {
        border-color: rgba(255, 255, 255, 0.13) !important;
      }

      .seed-item {
        .round-id {
          border: 1px solid var(--falcon-300);
          background: #232e3c;
          color: white;
        }

        .seed-team {
          border-color: rgba(255, 255, 255, 0.13);
          background: rgba(255, 255, 255, 0.06);
          color: #484848;

          * {
            color: white !important;
          }
        }
      }
    }
  }
}
